<template>
  <el-dialog :title="titleData[operType]" size="60%" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" label-width="90px" :rules="rules">
      <el-form-item label="前端语言" prop="language">
        <el-radio-group v-model="form.language">
          <el-radio label="vue">vue</el-radio>
          <el-radio label="react">react</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签" prop="labelId">
        <div class="flex-bc">
          <el-select v-model="form.labelId" clearable filterable @change="labelChange" style="width: 100%" class="mr10">
            <el-option v-for="item in labelList" :key="item.id" :value="item.id" :label="item.label"></el-option>
          </el-select>
          <el-button type="primary" plain icon="el-icon-plus" @click="addLabel"></el-button>
        </div>
      </el-form-item>
      <el-form-item label="框架名称" prop="name">
        <el-input v-model="form.name" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="框架编码" prop="code">
        <el-input v-model="form.code" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="框架版本" prop="version">
        <el-input v-model="form.version" clearable placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="布局主题" prop="layoutTheme">
        <el-select v-model="form.layoutTheme" clearable filterable :disabled="form.id" style="width: 100%">
          <el-option v-for="item in layoutThemes" :key="item.dictionaryValue" :value="item.dictionaryValue" :label="item.dictionaryLabel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="前端框架" prop="frontEndFramework">
        <el-select v-model="form.frontEndFramework" clearable filterable :disabled="form.id" style="width: 100%">
          <el-option v-for="item in frontEndFrameworks" :key="item.dictionaryValue" :value="item.dictionaryValue" :label="item.dictionaryLabel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="微前端框架" prop="microFrontEndFramework">
        <el-select v-model="form.microFrontEndFramework" clearable filterable :disabled="form.id" style="width: 100%">
          <el-option v-for="item in microFrontEndFrameworks" :key="item.dictionaryValue" :value="item.dictionaryValue" :label="item.dictionaryLabel"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="框架描述">
        <el-input type="textarea" placeholder="请输入" clearable v-model="form.description"> </el-input>
      </el-form-item>
      <el-button type="primary" plain @click="addTable">新增前端工程</el-button>
      <el-table class="mt10" ref="table" :data="form.frontProjects" border>
        <el-table-column label="工程名称" align="center" class="tableInput">
          <template slot-scope="scope">
            <el-form-item :prop="'frontProjects.' + scope.$index + '.projectName'" :rules="rules.projectName" label-width="0" style="margin-bottom: 0">
              <el-input v-model="scope.row.projectName" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="工程地址" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'frontProjects.' + scope.$index + '.projectUrl'" :rules="rules.projectUrl" label-width="0" style="margin-bottom: 0">
              <el-input v-model="scope.row.projectUrl" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="标签" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'frontProjects.' + scope.$index + '.label'" :rules="rules.label" label-width="0" style="margin-bottom: 0">
              <el-input v-model="scope.row.label" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="代码分支" align="center">
          <template slot-scope="scope">
            <el-form-item :prop="'frontProjects.' + scope.$index + '.projectBranch'" :rules="rules.projectBranch" label-width="0" style="margin-bottom: 0">
              <el-input v-model="scope.row.projectBranch" placeholder="请输入" clearable></el-input>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="80px">
          <template slot-scope="scope">
            <el-button plain type="warning" icon="el-icon-delete" @click="delTable(scope.$index)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="onSubmit">确定</el-button>
    </div>
    <el-drawer title="标签管理" :visible.sync="lableDrawer" :append-to-body="true" size="85%" direction="rtl" @close="getLabel()">
      <lableMgt></lableMgt>
    </el-drawer>
  </el-dialog>
</template>
<script>
import Api from '@/api/devops/framework'
import commonApi from '@/api/devops/common'
import lableMgt from '../../common/labelMgt.vue'
export default {
  components: { lableMgt },
  props: {
    show: {
      default: true,
      type: Boolean,
    },
    formData: { default: () => {}, type: Object },
    operType: { type: String, default: 'add' },
    labelList: {
      default: () => [],
      type: Array,
    },
  },

  watch: {
    show(val) {
      if (val) {
        if (this.operType == 'edit') {
          this.form = { ...this.formData }
        } else {
          this.form = {
            language: 'vue',
            frontProjects: [],
          }
        }
      }else {
        this.$refs.form?.resetFields()
      }
    },
  },
  data() {
    return {
      lableDrawer: false,
      titleData: {
        add: '新增前端框架',
        edit: '编辑前端框架',
        see: '前端框架详情',
      },
      form: {
        language: 'vue',
      },
      rules: {
        name: [{ required: true, message: '请输入', trigger: 'blur' }],
        labelId: [{ required: true, message: '请输入', trigger: 'blur' }],
        code: [{ required: true, message: '请输入', trigger: 'blur' }],
        projectName: [{ required: true, message: '请输入', trigger: 'blur' }],
        projectUrl: [{ required: true, message: '请输入', trigger: 'blur' }],
        label: [{ required: true, message: '请输入', trigger: 'blur' }],
        projectBranch: [{ required: true, message: '请输入', trigger: 'blur' }],
        version: [{ required: true, message: '请输入', trigger: 'blur' }],
        language: [{ required: true, message: '请输入', trigger: 'blur' }],
        // layoutTheme: [{ required: true, message: '请选择', trigger: 'blur' }],
        // frontEndFramework: [{ required: true, message: '请选择', trigger: 'blur' }],
        // microFrontEndFramework: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      layoutThemes: [],
      frontEndFrameworks: [],
      microFrontEndFrameworks: [],
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      this.layoutThemes = (
        await Api.getDataList({
          current: 1,
          size: 99,
          dictionaryType: 'front_end_theme',
        })
      ).data.records
      this.frontEndFrameworks = (
        await Api.getDataList({
          current: 1,
          size: 99,
          dictionaryType: 'front_end_frame',
        })
      ).data.records
      this.microFrontEndFrameworks = (
        await Api.getDataList({
          current: 1,
          size: 99,
          dictionaryType: 'front_end_micro',
        })
      ).data.records
    },
    close() {
      this.$emit('close')
    },
    onSubmit() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.frontProjects.length == 0) {
            this.$msg.error('前端工程不能为空')
          } else {
            this.fetch()
          }
        } else {
          return false
        }
      })
    },
    async fetch() {
      let params = this.form
      const fn = this.form.id ? Api.updateFront : Api.addFront
      await fn(params)
      this.$message.success('操作成功')
      this.$emit('getData')
      this.close()
    },

    labelChange(value) {
      this.labelList.forEach((item) => {
        if (item.id === value) {
          this.form.label = item.label
        }
      })
    },

    addTable() {
      this.form.frontProjects.push({
        projectName: '',
        projectUrl: '',
        projectBranch: '',
      })
    },
    delTable(index) {
      this.form.frontProjects.splice(index, 1)
    },

    async getLabel() {
      let res = await commonApi.getLabel()
      if (res.data) {
        this.labelList = res.data
      }
    },
    addLabel() {
      this.lableDrawer = true
    },
  },
}
</script>
<style lang="scss" scoped>
.inputWidth {
  width: 80%;
}
</style>
