import http from "@/utils/axios";

const DevOpsApi = {
    // 后端框架
    addEnd: (data) => http.post("eds/back/framework/add", data),
    getEndAll: (data) => http.get("eds/back/framework/select", data),
    updateEnd: (data) => http.put("eds/back/framework/update", data),
    deEnd: (data) => http.delete("eds/back/framework/delete/"+data),
    queryPage: (data) => http.get("eds/back/framework/query/page", data),
    getPreview: (data) => http.get("eds/back/framework/code/preview/" + data),
    delVersion: (data) => http.delete("eds/back/framework/version/delete/" + data),
    getVersion: (data) => http.get("eds/back/framework/version/select/"+ data),
    getVersionPage: (data) => http.get("eds/back/framework/version/select/page", data),
    addVersion: (data) => http.post("eds/back/framework/version/add", data),
    updateVersion: (data) => http.put("eds/back/framework/version/update", data),
    
        
    //前端框架
    getFront: (data) => http.get("eds/front/framework/query/page", data),
    deFront: (data) => http.delete("eds/front/framework/delete/" + data),
    addFront: (data) => http.post("eds/front/framework/add", data),
    updateFront: (data) => http.put("eds/front/framework/update", data),
    getFrontAll: (data) => http.get("eds/front/framework/query/all", data),
    getDataList: (data) => http.get(`/eds/system/dictionary/data/list`, data),
    //应用框架
    getApplication: (data) => http.get("eds/application/architecture/query/"+ data),
    deApplication: (data) => http.delete("eds/application/architecture/delete/" + data),
    addApplication: (data) => http.post("eds/application/architecture/add", data),
    updateApplication: (data) => http.put("eds/application/architecture/update", data),
    //工作流应用
    getWorkflow: (data) => http.get("eds/workflow/application/query/"+ data),
    addWorkflow: (data) => http.post("eds/workflow/application/add", data),
    updateWorkflow: (data) => http.put("eds/workflow/application/update", data),
    //系统框架
    getSystem: (data) => http.get("eds/system/architecture/query/page", data),
    deSystem: (data) => http.delete("eds/system/architecture/delete/" + data),
    addSystem: (data) => http.post("eds/system/architecture/add", data),
    updateSystem: (data) => http.put("eds/system/architecture/update", data),
    getSystemAll: (data) => http.get("eds/system/architecture/query/all", data),
    getFramework: (data) => http.get("eds/system/architecture/framework/query/", data),
    //模块列表接口(不分页)
    getModule: (data) => http.post("eds/application/architecture/module/list", data),
    getModuleVersion: (data) => http.post("eds/application/architecture/application/version/"+ data),
    
}
export default DevOpsApi;